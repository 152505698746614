import React from "react";
import Footer from "../../footer/Footer";
import HeaderTwo from "../../header/HeaderTwo";
import SliderTwo from "./SliderTwo";
import NewGamesArea from "./NewGamesArea";
import GamesAreatwo from "./GamesAreatwo";
import GamesArea from "./GamesArea";
import CtaArea from "./CtaArea";
import ShopAreaTwo from "./ShopAreaTwo";
import BlogArea from "./BlogArea";
import CellphoneArea from "./CellphoneArea";
import CommunityPartners from "./CommunityPartners";
import Omoonbeam from "./Ommonbeam";
import YellowBanner from "./YellowBanner";

function IndexTwo() {
  return (
    <>
      {/* header-area */}
      <HeaderTwo />

      {/* main-area */}
      <main>
        <SliderTwo /> 
        <NewGamesArea />
        <GamesAreatwo />
        {/* <ShopAreaTwo/> */}
        {/* <GamesArea /> */}
        <YellowBanner/>
        <CtaArea />
        <CellphoneArea />
        <CommunityPartners />
        <Omoonbeam />
        {/* <BlogArea /> */}
      </main>
      {/* main-area-end */}
      <Footer />
    </>
  );
}

export default IndexTwo;
