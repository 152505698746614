export const item5 = {
  title: "📊 XP & Rank",
  text: `
## XP and Rank Development in Polybots Rumble

![Polybots Rumble](/assets/img/wiki/Ranked.png)
In the world of **Polybots Rumble**, your journey to becoming a top contender is defined by your experience (XP) and rank progression. These two systems ensure that every battle you engage in contributes to your growth and standing in the competitive arena.

`,

  subTopics: {
    sub1: {
      title: "Accumulating XP",
      text: `
## Accumulating XP

XP is a constant reward in **Polybots Rumble**, earned through every battle you fight. Whether you win or lose, your efforts are acknowledged, allowing you to steadily advance and unlock new titles and abilities.

## How to Earn XP

- **Victory Rewards**: Winning a battle grants you 6 XP, with additional boosts based on your Polybot's remaining life:

  - **Over 90% Life**: +5 XP

  - **Over 50% Life**: +3 XP

  - **Over 10% Life**: +2 XP

  - **Below 10% Life**: No extra XP

  
- **Defeat Rewards**: Even in defeat, you earn 2 XP, ensuring that every battle contributes to your progress.
`,
    },

    sub2: {
      title: "Season's",
      text: `
## Seasonal Reset and Battle Pass

XP is accumulated throughout each season, resetting at the start of a new season. This system encourages continuous engagement and offers players fresh opportunities to climb the ranks. Additionally, XP serves as the foundation for the **Battle Pass**, where higher XP levels unlock exclusive rewards.
`,
    },

    sub3: {
      title: "Rank Points",
      text: `
## Rank Points and Competitive Play

Rank Points (RP) are the cornerstone of your competitive journey in **Polybots Rumble**. Unlike XP, RP can be both gained and lost, adding a strategic depth to ranked matches.

## How to Earn and Lose Rank Points

- **Victory**: Winning a ranked match awards you the same amount of RP as XP, bolstering your rank.

- **Defeat**: Losing a ranked match results in a loss of RP, equivalent to half the points your opponent gained.
`,
    },

    sub4: {
      title: "Titles and Ranks",
      text: `
## Titles and Ranks

Your XP and RP contribute to your rank, which ranges from **Bronze** to the prestigious **Pro Player** tiers. Each rank encompasses multiple levels, reflecting your dedication and skill:

- **Bronze**: The starting tier, where new players begin their journey.

- **Silver**: Demonstrates growing competence and understanding of the game mechanics.

- **Gold**: Marks significant skill and strategic prowess.

- **Diamond**: Represents top-tier players with advanced abilities and tactics.

- **Platinum**: Reserved for the elite, showcasing exceptional mastery.

- **Pro Player**: The ultimate tier, divided into Regional, World, and Elite levels, representing the pinnacle of competitive play.
`,
    },

    sub5: {
      title: "Progression",
      text: `
## Strategic Depth and Progression

In **Polybots Rumble**, every match matters. Whether you're climbing the ranks in intense competitive play or steadily gathering XP through casual battles, your path to greatness is paved with challenges and triumphs. Customize your Polybots, adapt your strategies, and rise through the ranks to become a legend in the world of **Polybots Rumble**.
`,
    },
  },
};
