export const item12 = {
  title: "🎁 Rewards",
  text: `
## Rewards

Prepare yourself for the incredible Polybot Rewards! 🚀 Whether you're collecting daily bonuses, completing challenges, or participating in special events, there's always something to earn. From coins to gems, you'll find exciting rewards that boost your gameplay. And it doesn’t stop there—new and even more exclusive rewards are on the horizon! Keep playing, keep collecting, and stay tuned for more thrilling updates in the Polybot universe! 💥

`,

  subTopics: {

    sub1: {
      title: "Daily Login",
      text: `
## Daily Login

![Banner Daily](/assets/img/wiki/banner_daily2.png)

Unlock exciting rewards with our Daily Reward System! 🎉 Log in every day to collect amazing bonuses, with increasing rewards as the days go by. Stack up your gold and gems as you progress, but here's the best part: make it to at least Day 4, and you'll unlock the chance to spin the Prize Wheel on the final day! Spin for a special prize and make your daily logins even more rewarding. Don't miss out—start collecting today and hit Day 7 for the ultimate reward!
      `,
    },

    sub2: {
      title: "Roullete",
      text: `
## Roullete


Get ready for the Ultimate Prize Spin! 🎉 When you reach the final day of your daily rewards journey, if you've collected at least four daily rewards, you'll unlock the special prize wheel! Spin the wheel and let the excitement begin as you have the chance to win random prizes like valuable coins or shiny gems. The more days you collect, the closer you get to this thrilling opportunity. Don’t miss your shot—spin the wheel and see what treasure awaits you! 💎🪙
      `,
    },
  },
};
