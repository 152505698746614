export const item1 = {
  title: "📜 History",
  text: `
## Awakening the Guardians

![Guardians of Fuji](/assets/img/wiki/MountFuji.png)

According to the legend, the Guardians of Fuji can be awakened by specific celestial alignments or mystical incantations. It is said that their eyes, glowing with an ethereal light, reflect their readiness to spring into action. Many believe that these protectors are intertwined with the very essence of Mount Fuji, drawing their power from its sacred grounds.

In the hidden caverns of Mount Fuji, the guardians slumber, embodying the spirit of ancient Japan, ever ready to rise and shield the land from any peril.

`,

  subTopics: {
    sub1: {
      title: "Guardians",
      text: `
## Powers of the Guardians

![Expeditions](/assets/img/wiki/CaveHistory2.png)

Embedded deep within the lore of ancient Japan, the **Guardians of Fuji** are more than just statues. They are sentinels imbued with extraordinary powers that transcend human understanding.

- **Elemental Control**: The ability to command the elements, from raging storms to earth-shattering quakes.

- **Unbreakable Fortitude**: Composed of materials unknown to modern science, they are virtually indestructible.

- **Ancient Wisdom**: Possessing knowledge from ages past, guiding and protecting the realm.

- **Mystical Synergy**: Their power is not only physical but also deeply connected to the mystical energies of the earth and the cosmos.
      `,
    },

    sub2: {
      title: "The Power",
      text: `
## Harnessing Their Abilities

![Expeditions](/assets/img/wiki/CaveHistory.png)

The Guardians' powers are not merely for protection, they are said to be able to manipulate the very fabric of reality. Ancient scripts speak of their ability to alter weather patterns, heal the land, and communicate telepathically with chosen individuals. This connection with nature and the spiritual world makes them revered not just as warriors but as sacred entities.

Researchers at Yuto Synthetics have only begun to scratch the surface of the Guardians' capabilities. Each new discovery unveils a layer of complexity, hinting at a legacy of advanced knowledge and supernatural prowess that far exceeds modern technological achievements.
      `,
    },

    sub3: {
      title: "Expeditions",
      text: `
## The Quest for the Guardians: Yuto Synthetics' Expeditions

![Expeditions](/assets/img/wiki/jungle.png)

Driven by ancient legends and guided by modern technology, Yuto Synthetics embarked on a journey to uncover the **Guardians of Fuji**. Under the leadership of Dr. Yuto Nakamoto, a team of explorers delved into the mysteries of Mount Fuji.


The expeditions have not been without peril. Researchers faced numerous obstacles, from unstable geological conditions to inexplicable phenomena that defy scientific explanation. Reports of strange energy readings and unexplained disappearances have only added to the mystique surrounding the Guardians' domain.

Each exploration uncovers more of the enigmatic history and technology of the Guardians, revealing a complex civilization that once thrived around Mount Fuji. As Yuto Synthetics pushes deeper into the heart of the mountain, they are met with both the promise of untold power and the ominous warnings of the ancient guardians' true purpose.
      `,
    },

    sub4: {
      title: "Secret Agenda",
      text: `
## Yuto Synthetics: Pioneers with a Secret Agenda

![Expeditions](/assets/img/wiki/Yuto-Helicopter.png)

Founded in the early 21st century, **Yuto Synthetics Corporation** is a leader in biotechnology and robotics. Publicly celebrated for their innovative advances, the corporation harbors a deeper, clandestine objective known as **Operation Guardian**.

### The Shadow Operations

Beneath its polished exterior, Yuto Synthetics is involved in a web of shadowy operations. They have established secret research facilities and secured partnerships with dubious entities to further their goals. These alliances enable them to push the boundaries of science and ethics, often at the cost of transparency and public trust.

As the corporation delves deeper into the ancient technologies of the Guardians, they uncover potential uses that extend far beyond entertainment. From military applications to environmental manipulation, the possibilities are as vast as they are dangerous. Dr. Nakamoto's relentless pursuit of power through the Guardians' legacy threatens to unleash forces that could reshape the very fabric of society.

With each passing day, the stakes grow higher, and the line between protector and oppressor becomes increasingly blurred. Yuto Synthetics stands at the brink of a monumental discovery, but the path they tread is fraught with peril and ethical conundrums that could alter the course of history.
      `,
    },

    sub5: {
      title: "Polybots",
      text: `
## Polybots 

![StreetBattles](/assets/img/wiki/Battle2.png)

With the advent of **Guardian Chips**, Yuto Synthetics revolutionized entertainment, bringing the powers of the **Guardians of Fuji** to the youth in the form of Polybots. These mini robots, equipped with ancient abilities, became the centerpiece of thrilling street battles.


## Battle Highlights

- **Urban Arenas**: Epic battles take place across Japanese cities, transforming everyday streets into dynamic battlegrounds.

- **Diverse Abilities**: Each Polybot boasts unique powers derived from the ancient Guardians, offering endless strategic possibilities.

- **Cultural Phenomenon**: These battles have captured the imagination of a generation, making Polybots a nationwide sensation.

- **Champion of the Streets**: Competitions are fierce, with skilled fighters earning the coveted title of **Street Champion**, revered by peers and admired by fans.

## The Rise of Polybots

Polybots are more than just combatants; they are symbols of personal expression and strategy. Each Polybot can be customized, reflecting the owner's tactical preferences and creative flair. This personalization extends to the battlegrounds, where street battles can turn into elaborate spectacles, complete with holographic displays and interactive environments.

As the popularity of Polybot battles grows, so does the community around them. Enthusiasts gather in digital and real-life arenas to share strategies, modify their Polybots, and participate in tournaments that challenge both their tactical acumen and their ingenuity. The integration of ancient Guardian powers into modern robotics has created a new form of entertainment that is both thrilling and deeply connected to Japan's storied past.
      `,
    },
  },
};
