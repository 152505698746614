export const item6 = {
  title: "⚡ Turns & Energy",
  text: `
## Turn-Based Strategy and Energy System in Polybots Rumble

![Energy System](/assets/img/wiki/energy.png)

In **Polybots Rumble**, strategic mastery and efficient energy management are key to dominating the battlefield. This turn-based strategy game requires players to carefully plan their moves and optimize their Polybots' abilities to outmaneuver and outsmart their opponents.
`,

  subTopics: {
    sub1: {
      title: "Strategic Depth",
      text: `

## Turn-Based Combat

Each battle in **Polybots Rumble** unfolds in a series of turns, where players take actions in a sequential manner. This system allows for deep strategic planning, as you must anticipate your opponent's moves and react accordingly.

- **Tactical Decision-Making**: Choose your actions wisely, considering both offensive and defensive maneuvers.

- **Anticipate Opponents**: Predict and counter your adversary's strategies to gain the upper hand.
`,
    },

    sub2: {
      title: "Energy Management",
      text: `

## Recharge and Expenditure

Energy is the lifeblood of your Polybots, determining their ability to execute powerful attacks and abilities. Each round, your Polybots' energy recharges, allowing you to unleash a variety of moves.

- **Energy Recharge**: At the start of each round, your Polybots regain a set amount of energy, preparing them for the next sequence of actions.

- **Energy Costs**: Every attack and ability has a specific energy cost. Balancing your energy expenditure is crucial to maintaining a steady flow of offensive and defensive options.
`,
    },

    sub3: {
      title: "Strategic Energy",
      text: `
## Strategic Energy Use

- **High-Cost Abilities**: Reserve your energy for devastating high-cost attacks that can turn the tide of battle in your favor.

- **Low-Cost Moves**: Utilize low-cost abilities to chip away at your opponent's defenses and conserve energy for future rounds.

- **Energy Conservation**: Sometimes, the best move is to conserve energy, building up for a powerful combo in the following turns.
`,
    },

    sub4: {
      title: "Battlefield",
      text: `
## Mastering the Battlefield

- **Ability Synergy**: Customize your Polybots with abilities that complement each other, creating powerful synergies and combinations.

- **Adaptability**: Adjust your strategy based on the flow of the battle, adapting to your opponent's tactics and the evolving battlefield conditions.
`,
    },

    sub5: {
      title: "Strategic Skills",
      text: `
## Climbing the Ranks

- **Skill and Strategy**: Success in **Polybots Rumble** is a blend of skillful execution and strategic foresight. Climbing the ranks requires mastering both aspects of the game.

- **Continuous Improvement**: Learn from each battle, refining your strategies and improving your energy management to rise through the ranks.

  In **Polybots Rumble**, every turn is an opportunity to outthink your opponent and assert your dominance on the battlefield. Harness the power of your Polybots, manage your energy wisely, and lead your team to victory in this thrilling turn-based strategy game.
  
  `,
    },
  },
};
