import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { item1 } from "./../../assets/data/privacypolice/data1";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./PrivacyPolice.css";

function PrivacyPolice() {
  const data = item1;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div className="container">
            <div className="PrivacyPolice-content">
              <div className="content">
                <Markdown remarkPlugins={[remarkGfm]}>
                  {data.text}
                </Markdown>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default PrivacyPolice;
