import React from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const VideoContainer = styled.div`
  width: 85%;
  cursor: pointer;
  border: 5px solid #40a5f8;
  border-radius: 10px;
  overflow: hidden;
`;

const StyledMuxPlayer = styled.div`
  width: 100%;
  height: 100%;
  --dialog: none;
  --loading-indicator: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --rendition-selectmenu: none;
  --bottom-play-button: none;
  border-radius: 10px;
  display: block; // Certifique-se de que o player seja exibido como bloco
  object-fit: cover; // Garantir que o vídeo preencha o container
`;

function NewGamesArea() {
  return (
    <section className="released-games-area gray-bg pt-115 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8" style={{ marginBottom: '20px', textAlign: 'center' }}>
            <h2 style={{ color: 'black' }}>
              THE BEGINNING{" "}
              <span style={{ color: "#40a5f8" }}>OF THE LEGEND</span>
            </h2>
          </div>
        </div>
        <VideoWrapper>
          <VideoContainer>
            <StyledMuxPlayer
              as="mux-player"
              playback-id="y6rYYKnFRYbcLTjbYbz00LPAGfVFomuz201LgS5008tkNU"
              metadata-video-title="Test VOD"
              metadata-viewer-user-id="user-id-007"
              poster="https://i.imgur.com/OvCWRLZ.jpeg"
            ></StyledMuxPlayer>
          </VideoContainer>
        </VideoWrapper>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div
              className="section-title title-style-three text-center mb-20"
              style={{ marginTop: "20px" }}
            >
              <p style={{ color: "black" }}>
                Enter the Polybots Rumble universe and prove you have what it
                takes to become a legend. Accept the challenge – the battle
                begins now!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewGamesArea;



//Fica acima da ultima div//

{
  /* <div className="row align-items-center">
        <div className="col-xl-8 col-lg-12">
          <Slider className="released-game-active"
          asNavFor={nav2}
          ref={slider => (slider1 = slider)}
          arrows={false}
          >
            <div className="released-game-carousel">
              <div className="released-game-item">
                <div className="released-game-item-bg" />
                <div className="released-game-img">
                  <img src="assets/img/images/released_game_img01.jpg" alt="" />
                </div>
                <div className="released-game-content">
                  <div className="released-game-rating">
                    <h5>Rating :</h5>
                    <div className="released-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                  </div>
                  <h4>Call <span>of Duty</span></h4>
                  <div className="released-game-list mb-15">
                    <ul>
                      <li><span>Category :</span>Virtual Game</li>
                      <li><span>Model :</span>Compete 100 players</li>
                      <li><span>Controller :</span>Playstation 5 , Xbox , PS4</li>
                    </ul>
                  </div>
                  <p>Compete with 100 players on a remote thats island for winner takes showdown known issue</p>
                  <Link to="/#" className="btn btn-style-two">buy now</Link>
                </div>
              </div>
            </div>
            <div className="released-game-carousel">
              <div className="released-game-item">
                <div className="released-game-item-bg" />
                <div className="released-game-img">
                  <img src="assets/img/images/released_game_img02.jpg" alt="" />
                </div>
                <div className="released-game-content">
                  <div className="released-game-rating">
                    <h5>Rating :</h5>
                    <div className="released-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                  </div>
                  <h4>Battle <span>Grounds</span></h4>
                  <div className="released-game-list mb-15">
                    <ul>
                      <li><span>Category :</span>Virtual Game</li>
                      <li><span>Model :</span>Compete 100 players</li>
                      <li><span>Controller :</span>Playstation 5 , Xbox , PS4</li>
                    </ul>
                  </div>
                  <p>Compete with 100 players on a remote thats island for winner takes showdown known issue</p>
                  <Link to="/#" className="btn btn-style-two">buy now</Link>
                </div>
              </div>
            </div>
            <div className="released-game-carousel">
              <div className="released-game-item">
                <div className="released-game-item-bg" />
                <div className="released-game-img">
                  <img src="assets/img/images/released_game_img03.jpg" alt="" />
                </div>
                <div className="released-game-content">
                  <div className="released-game-rating">
                    <h5>Rating :</h5>
                    <div className="released-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                  </div>
                  <h4>Apex <span>Legends</span></h4>
                  <div className="released-game-list mb-15">
                    <ul>
                      <li><span>Category :</span>Virtual Game</li>
                      <li><span>Model :</span>Compete 100 players</li>
                      <li><span>Controller :</span>Playstation 5 , Xbox , PS4</li>
                    </ul>
                  </div>
                  <p>Compete with 100 players on a remote thats island for winner takes showdown known issue</p>
                  <Link to="/#" className="btn btn-style-two">buy now</Link>
                </div>
              </div>
            </div>
            <div className="released-game-carousel">
              <div className="released-game-item">
                <div className="released-game-item-bg" />
                <div className="released-game-img">
                  <img src="assets/img/images/released_game_img03.jpg" alt="" />
                </div>
                <div className="released-game-content">
                  <div className="released-game-rating">
                    <h5>Rating :</h5>
                    <div className="released-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                  </div>
                  <h4>Monster <span>Hunter</span></h4>
                  <div className="released-game-list mb-15">
                    <ul>
                      <li><span>Category :</span>Virtual Game</li>
                      <li><span>Model :</span>Compete 100 players</li>
                      <li><span>Controller :</span>Playstation 5 , Xbox , PS4</li>
                    </ul>
                  </div>
                  <p>Compete with 100 players on a remote thats island for winner takes showdown known issue</p>
                  <Link to="/#" className="btn btn-style-two">buy now</Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="col-xl-4 col-lg-12">
          <Slider className="released-game-nav" 
          asNavFor={nav1}
          swipeToSlide={true}
          focusOnSelect={true}
          ref={slider => (slider2 = slider)} slidesToShow={3} vertical={true} arrows={false} >
            <div className="released-game-nav-item">
              <img src="assets/img/images/release_game_nav01.jpg" alt="" />
            </div>
            <div className="released-game-nav-item">
              <img src="assets/img/images/release_game_nav02.jpg" alt="" />
            </div>
            <div className="released-game-nav-item">
              <img src="assets/img/images/release_game_nav03.jpg" alt="" />
            </div>
            <div className="released-game-nav-item">
              <img src="assets/img/images/release_game_nav02.jpg" alt="" />
            </div>
          </Slider>
        </div>
      </div> */
}
