import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import "./Profile.css";
import WalletConnect from "../../components/web3/walletconnect";
import { getAuth, signOut } from "firebase/auth";
import { FiLogOut } from "react-icons/fi";

function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {})
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const idToken = localStorage.getItem("jwt");
        const response = await fetch(
          "https://us-central1-polybots-d02df.cloudfunctions.net/checkUserAndGetInfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erro ao buscar os dados do perfil");
        }

        const data = await response.json();
        setProfileData(data);
        setLoading(false);

        if (data.wallet !== "none") {
          setTermsAccepted(true);
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do perfil:", error);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    if (showTerms) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup the style on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showTerms]);

  const avatarMapping = {
    0: "assets/img/polybots/Avatars/Andy_New.png",
    1: "assets/img/polybots/Avatars/Tai_New.png",
    2: "assets/img/polybots/Avatars/Max_New.png",
    3: "assets/img/polybots/Avatars/Aria.png",
    4: "assets/img/polybots/Avatars/Sami.png",
    5: "assets/img/polybots/Avatars/Yuto.png",
    6: "assets/img/polybots/Avatars/Dra.Yumi.png",
    7: "assets/img/polybots/Avatars/Yuri.png",
  };

  const backgroundMapping = {
    0: "assets/img/polybots/Backgrounds/red_bg.png",
    1: "assets/img/polybots/Backgrounds/Blue.png",
    2: "assets/img/polybots/Backgrounds/Green_new.png",
    3: "assets/img/polybots/Backgrounds/pink_bg.png",
    4: "assets/img/polybots/Backgrounds/violet_bg.png",
    5: "assets/img/polybots/Backgrounds/alpha_png_BG.png",
    6: "assets/img/polybots/Backgrounds/beta_bg.png",
    7: "assets/img/polybots/Backgrounds/moonbeam.png",
  };

  const flagMapping = {
    argentina: "assets/img/polybots/Flags/argentina.png",
    australia: "assets/img/polybots/Flags/australia.png",
    brazil: "assets/img/polybots/Flags/brazil.png",
    croatia: "assets/img/polybots/Flags/croatia.png",
    default: "assets/img/polybots/Flags/defeault.png",
    england: "assets/img/polybots/Flags/england.png",
    france: "assets/img/polybots/Flags/france.png",
    germany: "assets/img/polybots/Flags/germany.png",
    iceland: "assets/img/polybots/Flags/iceland.png",
    italy: "assets/img/polybots/Flags/italy.png",
    jamaica: "assets/img/polybots/Flags/jamaica.png",
    japan: "assets/img/polybots/Flags/japan.png",
    poland: "assets/img/polybots/Flags/poland.png",
    russia: "assets/img/polybots/Flags/russia.png",
    "south-africa": "assets/img/polybots/Flags/south-africa.png",
    switzerland: "assets/img/polybots/Flags/switzerland.png",
    tunisia: "assets/img/polybots/Flags/tunisia.png",
    "united-states": "assets/img/polybots/Flags/united-states.png",
    uruguay: "assets/img/polybots/Flags/uruguay.png",
  };

  function calculateLevel(totalXP) {
    const BaseXP = 100;
    const LevelMultiplier = 1.2;

    let level = 1;
    let currentThreshold = BaseXP;
    let previousThreshold = 0;

    while (totalXP >= currentThreshold) {
      level++;
      previousThreshold = currentThreshold;
      currentThreshold += Math.floor(
        BaseXP * Math.pow(LevelMultiplier, level - 1)
      );
    }

    const xpGained = totalXP - previousThreshold;
    const xpNeeded = currentThreshold - totalXP;

    return { level, xpGained, xpNeeded };
  }

  const handleAcceptTerms = () => {
    setShowTerms(false);
    setTermsAccepted(true);
  };

  const handleRejectTerms = () => {
    setShowTerms(false);
  };

  const handleJoinFestivalClick = () => {
    setShowTerms(true);
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container>
              {!profileData ? (
                <div className="loading-container-profile">
                  <div className="loading-wrapper-profile">
                    <h1 className="loading-title-profile">Loading...</h1>
                    <div className="loading-spinner-profile"></div>
                  </div>
                </div>
              ) : (
                <>
                  <ProfileCard>
                    <AvatarBackgroundContainer>
                      <Background
                        src={backgroundMapping[profileData.avatarId]}
                        alt="Background"
                      />
                      <Avatar
                        src={avatarMapping[profileData.avatarId]}
                        alt="Avatar"
                      />
                    </AvatarBackgroundContainer>
                    <InfoContainer>
                      <TopInfo>
                        {profileData.nickname === "default" && (
                          <Username>Not Defined</Username>
                        )}
                        {profileData.nickname !== "default" && (
                          <Username>{profileData.nickname}</Username>
                        )}
                        <Flag
                          src={flagMapping[profileData.nationality]}
                          alt={`${profileData.nationality} Flag`}
                        />
                      </TopInfo>

                      {(() => {
                        const { level, xpGained, xpNeeded } = calculateLevel(
                          profileData.xp
                        );
                        return (
                          <>
                            <LevelContainer>
                              <Level>LVL: {level}</Level>
                            </LevelContainer>
                            <ProgressContainer>
                              <Progress>
                                <ProgressBar
                                  progress={
                                    (xpGained / (xpGained + xpNeeded)) * 100
                                  }
                                />
                              </Progress>
                            </ProgressContainer>
                          </>
                        );
                      })()}

                      <WinLossContainer>
                        <WinLoss>
                          W {profileData.win} / {profileData.lose} L
                        </WinLoss>
                      </WinLossContainer>
                    </InfoContainer>
                  </ProfileCard>

                  {showTerms ? (
                    <ModalContainer>
                      <ModalContent>
                        <h2>Lunar Game Festival</h2>
                        <TermsText>
                          The Lunar Gaming Festival promotes games on the
                          Moonbeam network, where participants play, complete
                          tasks, and earn cryptocurrency rewards. In the
                          Polybots Rumble, there will be a special mode during
                          the event. By playing 3 matches, players will receive
                          an exclusive Moonbeam background and avatar, available
                          only during the festival.
                          <br />
                          <br />
                          More details at{" "}
                          <LinkA
                            href="https://lunargamingfestival.com/"
                            target="_blank"
                          >
                            Lunar Gaming Festival
                          </LinkA>
                          .
                        </TermsText>

                        <ButtonGroup>
                          <Button onClick={handleAcceptTerms}>Accept</Button>
                          <Button onClick={handleRejectTerms}>Reject</Button>
                        </ButtonGroup>
                      </ModalContent>
                    </ModalContainer>
                  ) : termsAccepted ? (
                    <WalletConnect user={profileData} />
                  ) : (
                    <div className="slider-content">
                      <Fade up delay={0.8}>
                        <a
                          href="#"
                          className="btn btn-style-two"
                          style={{ marginTop: "40px", width: "250px" }}
                          onClick={handleJoinFestivalClick}
                        >
                          Join Festival
                        </a>
                      </Fade>
                    </div>
                  )}

                  <LogoutButton onClick={handleLogout}>
                    <FiLogOut size={20} style={{ marginRight: "5px" }} />
                    Logout
                  </LogoutButton>
                </>
              )}
            </Container>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const Container = styled.div`
  background-color: #1c1424;
  border-radius: 20px;
  width: 80%;
  height: 600px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 10px;
  background-image: url("assets/img/bg/garage.jpeg");
  background-size: cover;
  background-position: center;
  position: relative; /* Adicionado */

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const ProfileCard = styled.div`
  display: flex;
  background-color: #273444;
  border-radius: 10px;
  padding: 15px;
  width: 350px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 500px) {
    width: 300px;
  }

  @media (max-width: 450px) {
    width: 285px;
  }
`;

const AvatarBackgroundContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const LevelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProgressContainer = styled.div`
  margin-top: 5px;
`;

const WinLossContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Avatar = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
`;

const Username = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
`;

const Flag = styled.img`
  width: 30px;
  height: 30px;
`;

const Level = styled.div`
  color: #00bfff;
  font-size: 16px;
`;

const Progress = styled.div`
  background-color: #444b5b;
  border-radius: 5px;
  width: 100%;
`;

const ProgressBar = styled.div`
  background-color: #00bfff;
  border-radius: 5px;
  width: ${({ progress }) => progress}%;
  height: 8px;
`;

const WinLoss = styled.div`
  background-color: #273444;
  border-radius: 5px;
  padding: 4px 8px;
  color: #00ff00;
  font-weight: bold;
  font-size: 14px;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #273444;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  color: #fff;
  margin: 18px;
  overflow-y: auto; /* Adicione esta linha */
`;

const TermsText = styled.div`
  height: 243px;
  overflow-y: auto;
  text-align: justify;
  margin-bottom: 20px;
  padding: 20px;
  scroll-behavior: smooth;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #00bfff #273444;

  @media (max-width: 500px) {
    font-size: 13px;
    height: 280px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: #0099cc;
  }
`;

const LinkA = styled.a`
  color: #00bfff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #0099cc;
  }
`;

const LogoutContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const LogoutButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6347;
  color: white;
  padding: 8px 16px; /* Menor tamanho */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: #e5533d;
  }

  @media (max-width: 500px) {
    padding: 8px;
    &::before {
      font-size: 16px;
    }
    &::after {
      content: none;
    }
  }
`;

export default Profile;
