import React from "react";
import styled from "styled-components";

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Preenche o contêiner e corta o excesso */

  @media (max-width: 768px) {
    content: url("/assets/img/yellowbanner/banner_yellow_background_mobile.png");
  }
`;

const PhoneImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  min-width: 300px;
  width: 60%;
  height: auto;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

function YellowBanner() {
  return (
    <BannerContainer>
      <BackgroundImage src="/assets/img/yellowbanner/banner_yellow_background.png" />
      <PhoneImage src="/assets/img/yellowbanner/phone_games_polybots.png" />
    </BannerContainer>
  );
}

export default YellowBanner;
