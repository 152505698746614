import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { tr } from "date-fns/locale";

function CommunityPartners() {
  const handleDiscordClick = () => {
    window.open("https://discord.com/invite/vzWU7yMCut", "_blank");
  };

  const partners_list = [
    { src: "assets/img/partners/comunnitys/1.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/2.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/3.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/4.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/5.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/6.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/7.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/8.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/9.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/10.png", title: "", url: "" },
    { src: "assets/img/partners/comunnitys/11.png", title: "", url: "" },
  ];

  const settings = {
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const HoverImage = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 80%;
    cursor: pointer;
    max-width: 150px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }

    @media (max-width: 450px) {
      max-width: 100px;
    }
  `;

  const ImageDiv = styled.div`
    width: 70%;
    margin-top: 50px;

    @media (max-width: 450px) {
      margin-top: 0px;
    }
  `;

  const Section = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  `;

  const Divider = styled.div`
    width: 200px;
    height: 2px;
    background-color: white;
    margin: 0 0 20px 0;
  `;

  return (
    <section
      className="cta-area cta-bg"
      style={{ backgroundImage: "url(assets/img/bg/city_bg.png)" }}
    >
      <Section>
        <div
          className="white-title mb-50"
          style={{ marginBottom: "50px", textAlign: "center" }}
        >
          <h2>
            <span style={{ color: "#40a5f8" }}>COMMUNITY'S</span> PARTNERS
          </h2>
        </div>
        {/* <Divider /> */}
        <ImageDiv>
          <Slider {...settings}>
            {partners_list.map((item, index) => (
              <div key={index}>
                {/* <a href={item.url} target="_blank" rel="noopener noreferrer"> */}
                  <HoverImage
                    src={item.src}
                    alt={item.title}
                    className="hover-effect"
                  />
                {/* </a> */}
              </div>
            ))}
          </Slider>
        </ImageDiv>
      </Section>
    </section>
  );
}

export default CommunityPartners;
