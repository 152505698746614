export const item2 = {
  title: "🤖 Polybots Assembly",
  text: `
## Unraveling the Powers of the Guardians of Fuji

![Polybots Assembly](/assets/img/wiki/Costumization.png)

In the thrilling world of **Polybot**, the battle for supremacy begins with the perfect assembly of your Polybot. Yuto Synthetics has unlocked the secrets of the ancient Guardians of Fuji, allowing players to harness their incredible powers and craft their ultimate fighting machine. Dive into the action and customize every aspect of your Polybot to match your unique style and strategy!
`,

  subTopics: {
    sub1: {
      title: "Customization",
      text: `
## Customize Your Polybot: Unleash Your Inner Mecha Warrior

![Customization](/assets/img/wiki/Costumization.png)

Each Polybot is composed of six essential parts: a head, chest, two arms, and two legs. Every component can be customized to enhance specific attributes and powers, enabling you to create a robot that perfectly fits your battle strategy. Mix and match different parts to discover the most effective combinations and dominate the competition.
      `,
    },

    sub2: {
      title: "Attributes",
      text: `
## Attributes

- **Attack**: Amplify your offensive capabilities with parts designed to maximize your Polybot's destructive power.

- **Defense**: Fortify your Polybot's resilience with robust components that can withstand the fiercest attacks.

- **Health**: Boost your Polybot's endurance, ensuring it can outlast opponents in prolonged battles.

- **Agility**: Enhance your Polybot's speed and maneuverability, giving you a tactical edge on the battlefield.

- **Energy**: Optimize your Polybot's energy efficiency to maintain peak performance throughout intense combat.
      `,
    },

    sub3: {
      title: "Combinations",
      text: `
## Power Combinations

The true power of your Polybot lies in the synergy of its parts. Each combination unlocks unique abilities and special moves, allowing for a diverse range of fighting styles:

- **Elemental Fury**: Equip parts infused with elemental control to unleash devastating attacks and control the battlefield.

- **Indomitable Shield**: Assemble a Polybot with maximum defense and health to become an unbreakable force.

- **Swift Striker**: Prioritize agility and attack for a fast, relentless combat style.

- **Balanced Warrior**: Create a well-rounded Polybot with balanced attributes to adapt to any situation.
      `,
    },
  },
};
