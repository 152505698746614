import styled from "styled-components";

export const Banner = styled.div`
  width: 100%;
  height: 85vh;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(0, 570px));
  background-image: url(assets/img/bg/polybots_banner4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    height: 90vh;
  }

  @media (max-width: 610px) {
    height: 90vh;
  }

  @media (max-width: 510px) {
    height: 80vh;
  }
`;

export const BannerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: inherit;
  user-select: none;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  position: relative;
  text-align: center;

  .PolybotText {
    margin: 20px;

    h4 {
      text-align: justify;
    }

    @media (max-width: 992px) {
      z-index: 2;
      position: absolute;
      margin: 50px;

      h4 {
        font-size: 1rem;
        text-align: center;
      }
    }

    @media (max-width: 510px) {
      margin-top: -50px;
    }

    @media (max-width: 420px) {
      h4 {
        font-size: 0.8rem;
      }
    }

    @media (max-width: 380px) {
      margin-top: -80px;
    }
  }

  .PolybotImage {
    z-index: 1;
    display: none;
    filter: brightness(45%);
    height: 80%;
    width: inherit;

    @media (max-width: 992px) {
      display: block;
    }

    @media (max-width: 680px) {
      height: 70%;
      display: block;
    }

    @media (max-width: 590px) {
      height: 50%;
      display: block;
    }

    @media (max-width: 380px) {
      height: 45%;
      display: block;
    }

    @media (max-width: 340px) {
      height: 40%;
      display: block;
    }
  }
`;

export const BannerClasses = styled.div`
  z-index: 3;
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 150px;
  text-align: center;
  gap: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
    gap: -50px;
    row-gap: -20px;
    height: 200px;
    margin-top: -80px;
  }

  @media (max-width: 610px) {
    margin-top: -70px;
  }
`;

export const Tier = styled.div`
  width: 75px;
  height: 75px;
  rotate: 45deg;
  background-color: #1a1322;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  h2 {
    margin: auto;
    color: #3498db;
    rotate: -45deg;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }

  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;

    h2 {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 510px) {
    width: 50px;
    height: 50px;
    margin-top: -40px;
    h2 {
      font-size: 1rem;
    }
  }
`;

export const FlexPolybots = styled.div`
  padding: 4rem 0 8rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #f8f8f8;
`;

export const GridPolybots = styled.div`
  width: 100%;
  max-width: 1140px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 1024px) {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }

  @media (max-width: 767px) {
    width: 50%;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  @media (max-width: 650px) {
    width: 60%;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  @media (max-width: 550px) {
    width: 70%;
  }

  @media (max-width: 550px) {
    width: 80%;
  }
`;

export const Polybot = styled.div`
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 400px;
  display: grid;
  grid-template-rows: 4fr 1fr;

  border: 2px solid
    ${({ tier }) => {
      switch (tier) {
        case "C":
          return "rgba(176, 196, 222, 0.5)";
        case "B":
          return "rgba(0, 255, 127, 0.5)";
        case "A":
          return "rgba(52, 152, 219, 0.5)";
        case "S":
          return "rgba(138, 43, 226, 0.5)";
        case "S+":
          return "rgba(255, 69, 0, 0.5)";
        default:
          return "transparent";
      }
    }};

  box-shadow: 0 0 10px
    ${({ tier }) => {
      switch (tier) {
        case "C":
          return "rgba(176, 196, 222, 0.5)";
        case "B":
          return "rgba(0, 255, 127, 0.3)";
        case "A":
          return "rgba(52, 152, 219, 0.3)";
        case "S":
          return "rgba(138, 43, 226, 0.3)";
        case "S+":
          return "rgba(255, 69, 0, 0.3)";
        default:
          return "transparent";
      }
    }};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 300px;
    width: auto;
  }

  h2 {
    color: #222;
    margin-block: auto;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  @media (max-width: 767px) {
    img {
      heigth: 300px;
    }
  }
`;
