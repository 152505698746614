import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import styled from "styled-components";

const TitleDiv = styled.div`
  width: 150px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

function SliderTwo() {
  const windowWidth = useWindowWidth();
  const desktopMinus = windowWidth < 1500;
  const isMobile = windowWidth < 1200;
  const circleNone = windowWidth < 990;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  // useEffect(()=>{
  // new WOW.WOW({
  //   boxClass: 'wow',
  // 	animateClass: 'animated',
  // 	offset: 0,
  // 	mobile: false,
  // 	live: true,
  //   loop:Infinity,
  // }).init();

  // },[])

  return (
    <>
      <section className="slider-area">
        {/* <Slider className="slider-active" {...settings}> */}
        <div
          className="single-slider slider-bg slider-style-two"
          style={{ backgroundImage: "url(assets/img/bg/polybots_banner3.png)" }}
        >
          <div className="container-fluid container-full-padding">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-11">
                <div className="slider-content ">
                  <Fade up delay={0.4}>
                    <h6 style={{ color: "#40a5f8" }}>World Gaming</h6>
                  </Fade>
                  <TitleDiv>
                    <Fade up delay={0.5}>
                      <h2>
                        Polybots <span>Rumble</span>
                      </h2>
                    </Fade>
                  </TitleDiv>
                  <Fade up delay={0.5}>
                    <p>
                      Choose your trainer, guide your Polybot in exciting
                      battles and enjoy your victory rewards.
                    </p>
                  </Fade>

                  <Fade up delay={0.8}>
                    <a
                      href="/download"
                      className="btn btn-style-two"
                    >
                      Download
                    </a>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <Slide right delay={0.4}>
            <div className="slider-img">
              {!desktopMinus && (
                <img src={"assets/img/slider/slider_img01.png"} alt="" />
              )}
              {desktopMinus && !isMobile && (
                <img
                  style={{ width: "600px" }}
                  src={"assets/img/slider/slider_img01.png"}
                  alt=""
                />
              )}
              {isMobile && (
                <img
                  style={{ width: "550px"}}
                  src={"assets/img/slider/slider_img03.png"}
                  alt=""
                />
              )}
            </div>
          </Slide>
          <Slide left delay={0.4}>
            <div className="slider-img slider-img2 wow slideInRightS">
              <img src="assets/img/slider/slider_img02.png" alt="" />
            </div>
          </Slide>
          {circleNone ? null : (
            <div className="slider-circle-shape">
              <img
                src="assets/img/slider/slider_circle.png"
                alt=""
                className="rotateme"
              />
            </div>
          )}
        </div>
        {/* <div className="single-slider slider-bg slider-style-two" style={{backgroundImage: 'url(assets/img/slider/slider_bg.jpg)'}}>
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content">
                        <Fade up delay={.4000}>
                        <h6>world gaming</h6>
                        </Fade>
                        <Fade up delay={.5000}>
                        <h2 >Cod <span>Modern</span> Warfare</h2>
                        </Fade>
                        <Fade up delay={.5000}>
                        <p>Find technology or people for digital projects in public sector and Find an individual specialist develope researcher.</p>
                        </Fade>
                        <Fade up delay={.8000}>
                        <a href="/#" className="btn btn-style-two">READ MORE</a>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
                <Slide right delay={.4000}>
                <div className="slider-img"><img src="assets/img/slider/slider_img01.png" alt="" /></div>
                </Slide>
                <Slide left delay={.4000}>
                <div className="slider-img slider-img2"><img src="assets/img/slider/slider_img02.png" alt="" /></div>
                </Slide>
                <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.png" alt="" className="rotateme" /></div>
              </div> */}
        {/* </Slider> */}
      </section>
    </>
  );
}

export default SliderTwo;
