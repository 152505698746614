import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Omoonbeam() {

  const HoverImage = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 90%;
    cursor: pointer;
    max-width: 100px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  `;


  return (
    <section className="cta-area cta-bg2" style={{backgroundColor:'white'}}>

<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginBottom:'50px'
        }}
      >
        <h2 style={{color:'black', fontSize:'40px'}}>
          POWERED <span style={{color: "#40a5f8" }}>BY</span>
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <a
            href="https://moonbeam.network/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HoverImage
              style={{ maxWidth: "400px", width: "100%" }}
              src="assets/img/partners/moonbeamb.png"
              alt="aaa"
              className="hover-effect"
            />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Omoonbeam;
