import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThirdwebProvider } from "thirdweb/react";
import { client } from "./context/client";

export const myChain = {
  chainId: 1284,
  rpc: ["https://rpc.api.moonbeam.network"],
  chainName: "Moonbeam",
  nativeCurrency: {
    name: "Glimmer",
    symbol: "GLMR",
    decimals: 18,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThirdwebProvider
    activeChain={myChain}
    client={client}
    switchActiveWalletChain={true}
  >
    <App />
  </ThirdwebProvider>,
  document.getElementById("root")
);
