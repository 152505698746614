export const item4 = {
  title: "🎮 Game Modes",
  text: `
## Exciting Game Modes in Polybots

![Game Modes](/assets/img/wiki/Fight.png)

Dive into the world of **Polybots** and experience a variety of thrilling game modes that cater to every type of player. Whether you're looking to train, casually compete, or climb the ranks, there's a mode for you!

`,

  subTopics: {
    sub1: {
      title: "Training Mode",
      text: `
## Training Mode

Perfect your Polybot's moves and configurations without the pressure of competition. In Training Mode, you can experiment with different setups and refine your strategies without consuming any Battle Energy. This is the ideal environment to master your skills.

- **Cost:** 0 Battle Energy

`,
    },

    sub2: {
      title: "Casual Match",
      text: `
## Casual Match

Engage in quick and fun battles against the first player found online. Casual Match is all about enjoying the thrill of combat without the stress of rankings. It's a great way to earn experience points and enjoy spontaneous skirmishes.

- **Cost:** 1 Battle Energy

- **Rewards:** XP Rules
`,
    },

    sub3: {
      title: "Adventure",
      text: `
## Adventure - PVE - Coming Soon

Prepare for an epic adventure mode where you will face off against formidable AI opponents. In this upcoming Player vs. Environment mode, you'll explore new storylines, uncover secrets, and challenge your Polybot against powerful adversaries. Stay tuned for more exciting updates!


`,
    },

    sub4: {
      title: "Ranked Match",
      text: `
## Ranked Match

Test your skills and strive for greatness in Ranked Match. Compete against players matched through our sophisticated matchmaking system based on ELO points. Earn XP, levels, ELO points, and see your rank rise or fall based on your performance. Each match brings intense competition and the chance to prove your dominance.

- **Cost:** 2 Battle Energy

- **Rewards:** DROP

- **Matchmaking:** ELO Points (wider range at the start of the game)
`,
    },

    sub5: {
      title: "Duel Mode",
      text: `
## Duel Mode

Challenge a friend to a one-on-one battle by creating a unique match code or entering a code generated by your opponent. Duel Mode allows you to enjoy personalized, competitive matches with your friends, adding an extra layer of strategy and excitement to your gameplay experience.

- **Cost:** 1 Battle Energy
- **Rewards:** XP Rules

### How to Play

![Duel](/assets/img/wiki/gamemode/duel/1.png)

##### **1:** First, enter Duel Mode.
##### **2:** Generate a match code and share it with your friend, or use the code they provide.
##### **3:** Send the code to your friend to start the match.

![Duel](/assets/img/wiki/gamemode/duel/3.png)

### Alternatively, if someone sends you a code:

![Duel](/assets/img/wiki/gamemode/duel/2.png)

##### **1:** Go to "Enter Room."
##### **2:** Enter the code your friend provided.
##### **3:** Start the game and enjoy the duel.
`,
    },
  },
};
