export const item7 = {
  title: "🛒 Store",
  text: `
## In-Game Store

![Banner](/assets/img/wiki/banners_wiki-01.png)

In **Polybots Rumble**, the in-game store is where players can acquire collectible boxes using gems. These boxes contain various parts that are essential for upgrading and customizing your Polybots.
`,

  subTopics: {
    sub1: {
      title: "Collectible Boxes",
      text: `
## Collectible Boxes

There are three types of collectible boxes, each offering different levels of quality in parts. The thrill of opening these boxes and discovering new parts adds a strategic layer to the game.

### Basic

![Collectible Boxes](assets/img/wiki/blue-box-300.png)

##### **Price:** 98 Gems 
##### **1 Star Chance:** 70%
##### **2 Star Chance:** 30%
##### **3 Star Chance:** 0%

- **Blue Box**: The most accessible option, offering a steady supply of parts that help build a strong foundation for your Polybots.

### Pro

![Collectible Boxes](assets/img/wiki/green-box-300.png)

##### **Price:** 198 Gems 
##### **1 Star Chance:** 40%
##### **2 Star Chance:** 55%
##### **3 Star Chance:** 5%

- **Green Box**: An intermediate option, providing better parts than the Blue Box, ideal for players looking to enhance their Polybots further.

### Advanced

![Collectible Boxes](assets/img/wiki/red-box-300.png)

##### **Price:** 490 Gems 
##### **1 Star Chance:** 15%
##### **2 Star Chance:** 70%
##### **3 Star Chance:** 15%

- **Red Box**: The premium option, offering the best quality parts, perfect for significantly boosting the performance and capabilities of your Polybots.
      `,
    },

    sub2: {
      title: "Adquiring Gems",
      text: `
## Adquiring Gems

Gems can be acquired through various in-game activities and purchases. Players can earn gems by completing missions, participating in events, or purchasing them directly through the in-game store.

- **Missions and Events**: Complete daily missions and special events to earn gems as rewards.

- **In-Game Store**: Purchase gems through the in-game store to quickly accumulate the currency needed for buying boxes.
      `,
    },
  },
};
