import React, { useRef } from "react";
import MuxPlayer from "@mux/mux-player-react";
import styled from "styled-components";

const PhoneContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
`;

const StyledMuxPlayer = styled(MuxPlayer)`
  --dialog: none;
  --loading-indicator: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --rendition-selectmenu: none;
  --bottom-play-button: none;
  border-radius: 20px;
  width: 100%;
  position: absolute;
  top: 1.5%;
  left: 18%;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 75%;
    height: 74%;
    left: 14.5%;
    top: 3%;
  }

  @media (max-width: 500px) {
    height: 72%;
  }

  @media (max-width: 350px) {
    height: 70%;
  }
`;

const PhoneImage = styled.img`
  width: 130%;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledImage = styled.img`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.95;
  width: 190px;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  @media (max-width: 400px) {
    width: 160px;
  }

  @media (max-width: 350px) {
    width: 140px;
  }
`;

function CellphoneArea() {
  const onReady = () => {
    console.log("Video is ready");
  };

  const videoRef = useRef(null);

  const handleContainerClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <section className="cta-area cta-bg2" style={{ backgroundColor: "white" }}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-6">
            <PhoneContainer onClick={handleContainerClick}>
              <PhoneImage src="assets/img/images/phone4.png" alt="Phone" />
              <StyledMuxPlayer
                streamType="on-demand"
                nohotkeys
                playbackId="bsX5AhMY00Pwcavytg4vdPGMPPMoTzh00puR0002FNYhVUI"
                metadataVideoTitle="Placeholder (optional)"
                metadataViewerUserId="Placeholder (optional)"
                primaryColor="#FF6666"
                secondaryColor="#000000"
                ref={videoRef}
                onReady={onReady}
                controls={true}
                poster="https://i.imgur.com/ugvlrmF.png"
              />
            </PhoneContainer>
          </div>
          <div className="col-lg-6">
            <div className="cta-content">
              <div className="section-title title-style-three white-title mb-50">
                <h2 style={{ color: "black" }}>
                  TAKE YOUR POLYBOT{" "}
                  <span style={{ color: "#40a5f8" }}>TO GLORY!</span>
                </h2>
                <p style={{ color: "black" }}>
                  Join the action-packed universe of Polybots Rumble. Customize
                  your fighting machine, equip an unbeatable arsenal, and fight
                  your opponents in thrilling battles.
                  <br />
                  <br />
                  Download for free and become a legend on the battlefield,
                  trainer.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "-30px",
                }}
              >
                <h1 style={{ color: "black", fontSize: "35px" }}>
                  Beta Already out!!!
                </h1>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 10px",
                  }}
                >
                  <StyledImage
                    src="assets/img/buttons/google-button.png"
                    alt="Google Play Button"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.kumagames.polybots&pli=1",
                        "_blank"
                      )
                    }
                  />
                  <StyledImage
                    src="assets/img/buttons/apple-button.png"
                    alt="Apple Store Button"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/br/app/polybots-rumble/id6499164472",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CellphoneArea;
