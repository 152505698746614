import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import "./Download.css";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const ButtonImage = styled.img`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.95;
  width: 250px;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  @media (max-width: 800px) {
    width: 250px;
  }

  @media (max-width: 400px) {
    width: 220px;
  }
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -300px;
  margin-bottom: 60px;

  @media (max-width: 1000px) {
    margin-top: -250px;
  }

  @media (max-width: 500px) {
    margin-top: -250px;
    margin-bottom: 40px;
  }
`;

const IconImage = styled.img`
  width: 300px;
  cursor: pointer;
  border-radius: 50%;

  @media (max-width: 800px) {
    width: 250px;
  }

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 180px;
  }
`;

function DownloadPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referParam = queryParams.get("refer");

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 370);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleButtonClick = async (platform) => {
    const refer = referParam || "Polybots";
    const platformValue = platform === "google" ? 1 : 2;
    const apiUrl =
      "https://us-central1-polybots-d02df.cloudfunctions.net/downloads";

    const body = {
      refer: refer,
      platform: platformValue,
    };

    const url =
      platform === "google"
        ? "https://play.google.com/store/apps/details?id=com.kumagames.polybots&pli=1"
        : "https://apps.apple.com/br/app/polybots-rumble/id6499164472";

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        console.log("Download registered successfully");
      } else {
        console.error("Failed to register download");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="download-bg">
          <div className="container">
            <div className="download-content">
              <div className="title-styly white-title mb-50">
                {isSmallScreen ? (
                  <h2 style={{ color: "White", fontSize: "25px" }}>
                    COMMAND YOUR POLYBOT {""}
                    <span style={{ color: "#40a5f8" }}>TO VICTORY!</span>
                  </h2>
                ) : (
                  <h2 style={{ color: "White" }}>
                    COMMAND YOUR POLYBOT {""}
                    <span style={{ color: "#40a5f8" }}>TO VICTORY!</span>
                  </h2>
                )}
              </div>
            </div>
            <IconDiv>
              <IconImage
                src="assets/img/icon/icon-polybots.png"
                alt="Polybots"
              />
            </IconDiv>

            {isSmallScreen ? (
              <h4 style={{ fontSize: "16px" }}>
                Choose your platform and download Polybots by clicking below:
              </h4>
            ) : (
              <h3>
                Choose your platform and download Polybots by clicking below:
              </h3>
            )}

            <ButtonDiv>
              <ButtonImage
                src="assets/img/buttons/google-button-white.png"
                alt="Google Play Button"
                onClick={() => handleButtonClick("google")}
              />
              <ButtonImage
                src="assets/img/buttons/apple-button-white.png"
                alt="Apple Store Button"
                onClick={() => handleButtonClick("apple")}
              />
            </ButtonDiv>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default DownloadPage;
