export const item3 = {
  title: "⚙️ Parts System",
  text: `
## Parts System Overview

![Parts System](/assets/img/wiki/Customization3.png)

In **Polybots Rumble**, upgrading your Polybots with advanced parts is crucial for gaining a competitive edge. Players can acquire and enhance these parts through various systems, adding a layer of strategy to the game.
`,

  subTopics: {
    sub1: {
      title: "Enchancing",
      text: `
## Enhancing Your Polybots

Opening boxes grants players a selection of random parts, ranging from basic components to high-performance upgrades. The anticipation and excitement of unlocking new parts create a rewarding experience.

- **Customization**: Use the parts to customize and enhance your Polybots, improving their combat capabilities and visual appearance.

- **Strategic Upgrades**: Equip your Polybots with the best parts to maximize their effectiveness in battles and adapt to different combat scenarios.
      `,
    },

    sub2: {
      title: "Collecting and Trading",
      text: `
## Collecting and Trading

- **Part Collection**: Build a diverse collection of parts to suit various strategic needs and preferences.

- **Trading System**: Trade duplicate or unwanted parts with other players to complete your ideal Polybot builds and expand your collection.
      `,
    },

    sub3: {
      title: "Potencial",
      text: `
## Maximizing Your Potential

Strategic spending is key to success in **Polybots Rumble**. Choose the right type of box based on your current needs and gem availability. Whether you're building a strong foundation with Blue Boxes or seeking powerful upgrades with Red Boxes, efficient upgrades are crucial.

- **Box Selection**: Select the type of box that aligns with your strategic goals and gem resources.

- **Efficient Upgrades**: Focus on obtaining parts that complement your existing Polybots and enhance their performance in battles.
      `,
    },
  },
};
