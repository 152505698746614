import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .cls-1 {
    fill: none;
    stroke: ${({ color }) => color || "#000"};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`;

const ArrowIcon = ({ color = "#000", size = 32, ...props }) => (
  <div
    style={{
      position: "relative",
      bottom: "12px",
      right: "8px",
    }}
  >
    <StyledSvg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      color={color}
      style={{ ...props }}
      {...props}
    >
      <g id="arrow-right">
        <line className="cls-1" x1="29.08" x2="3.08" y1="16" y2="16" />
        <line className="cls-1" x1="29.08" x2="25.08" y1="16" y2="21" />
        <line className="cls-1" x1="29.08" x2="25.08" y1="16" y2="11" />
      </g>
    </StyledSvg>
  </div>
);

export default ArrowIcon;
