import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import PolybotsModal from "../../components/modals/polybots/polybotsModal";
import {
  Banner,
  BannerImage,
  BannerText,
  BannerClasses,
  Tier,
  FlexPolybots,
  GridPolybots,
  Polybot,
} from "./polybotscss";

export default function Polybots() {
  const RobotBanner = "assets/img/robots/tiger.png";
  const RobotText = "assets/img/robots/1.png";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState(null);

  const [selectedTier, setSelectedTier] = useState(null);

  const [isClickable, setIsClickable] = useState(true);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsClickable(window.innerWidth >= 1000);
  //   };

  //   handleResize();

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const openModal = (robotName) => {
    setSelectedRobot(robotName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const robots = [
    {
      name: "YUT-001",
      imgSrc: "assets/img/polybots/polybots/9.png",
      tier: "C",
      modal: true,
    },
    {
      name: "YUT-002",
      imgSrc: "assets/img/polybots/polybots/10.png",
      tier: "C",
      modal: true,
    },
    {
      name: "ANK-590",
      imgSrc: "assets/img/polybots/polybots/5.png",
      tier: "B",
      modal: true,
    },
    {
      name: "TMN-380",
      imgSrc: "assets/img/polybots/polybots/7.png",
      tier: "B",
      modal: true,
    },
    {
      name: "FNX-210",
      imgSrc: "assets/img/polybots/polybots/3.png",
      tier: "B",
      modal: true,
    },
    {
      name: "NXT-480",
      imgSrc: "assets/img/polybots/polybots/4.png",
      tier: "B",
      modal: true,
    },
    {
      name: "MTH-600",
      imgSrc: "assets/img/polybots/polybots/8.png",
      tier: "B",
      modal: true,
    },
  ];

  const placeholderRobots = Array.from({ length: 6 }, (_, i) => ({
    name: "Coming Soon",
    imgSrc: `assets/img/polybots/polybots/1-${selectedTier || "A"}.png`,
    imgSrc: `assets/img/polybots/polybots/1-${selectedTier || "S"}.png`,
    imgSrc: `assets/img/polybots/polybots/1-${selectedTier || "S+"}.png`,
    tier: selectedTier,
    modal: false,
  }));

  const filteredRobots = selectedTier
    ? robots.filter((robot) => robot.tier === selectedTier)
    : robots;

  const isTierEmpty =
    ["A", "S", "S+"].includes(selectedTier) && filteredRobots.length === 0;

  return (
    <>
      {isModalOpen && (
        <PolybotsModal closeModal={closeModal} robotName={selectedRobot} />
      )}

      <HeaderTwo />
      <Banner>
        <BannerImage>
          <img
            src={RobotBanner}
            alt="Yellow Polybot"
            style={{
              height: "85%",
              width: "inherit",
              marginLeft: "-20px",
            }}
          />
        </BannerImage>
        <BannerText>
          <div className="PolybotText">
            <h1 style={{ fontSize: "4rem" }}>Polybots</h1>
            <h4>
              Polybots are the ultimate fusion of futuristic design and
              cutting-edge tech, bringing unmatched excitement to every battle.
              With customizable parts and powerful abilities, they evolve and
              grow stronger with each fight. Dive into a world where your
              Polybot is the coolest, most unstoppable force on the
              battlefield!.
            </h4>
          </div>
          <img src={RobotText} alt="Yellow Polybot" className="PolybotImage" />
        </BannerText>
        <BannerClasses>
          {" "}
          <div onClick={() => setSelectedTier(null)}>
            <Tier
              style={{
                border: "2px solid #FFD700",
                boxShadow: "rgba(255,215,0, 0.8) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#FFD700" }}>All</h2>
            </Tier>
          </div>
          <div onClick={() => setSelectedTier("C")}>
            <Tier
              style={{
                border: "2px solid #B0C4DE",
                boxShadow: "rgba(176,196,222, 0.8) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#B0C4DE" }}>C</h2>
            </Tier>
          </div>
          <div onClick={() => setSelectedTier("B")}>
            <Tier
              style={{
                border: "2px solid #00FF7F",
                boxShadow: "rgba(0,255,127, 0.6) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#00FF7F" }}>B</h2>
            </Tier>
          </div>
          <div onClick={() => setSelectedTier("A")}>
            <Tier
              style={{
                border: "2px solid #3498db",
                boxShadow: "rgba(52, 152, 219, 0.8) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#3498db" }}>A</h2>
            </Tier>
          </div>
          <div onClick={() => setSelectedTier("S")}>
            <Tier
              style={{
                border: "2px solid #8A2BE2",
                boxShadow: "rgba(138,43,226, 0.8) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#8A2BE2" }}>S</h2>
            </Tier>
          </div>
          <div onClick={() => setSelectedTier("S+")}>
            <Tier
              style={{
                border: "2px solid #FF4500",
                boxShadow: "rgba(255,69,0, 0.8) 0px 2px 8px 0px",
              }}
            >
              <h2 style={{ color: "#FF4500" }}>S+</h2>
            </Tier>
          </div>
        </BannerClasses>
      </Banner>
      <FlexPolybots style={{ flexDirection: "column" }}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "50px",
            color: "black",
            fontSize: "50px",
          }}
        >
          {selectedTier ? `Tier-${selectedTier}` : "All Polybots"}
        </h1>
        <GridPolybots>
          {(isTierEmpty ? placeholderRobots : filteredRobots).map(
            (robot, index) => (
              <Polybot
                key={index}
                onClick={() => {
                  if (!["A", "S", "S+"].includes(robot.tier)) {
                    openModal(robot.name);
                  }
                }}
                disabled={!robot.modal || ["A", "S", "S+"].includes(robot.tier)}
                tier={robot.tier}
              >
                <div>
                  <img src={robot.imgSrc} alt={robot.name} />
                </div>
                <div>
                  <h2>{robot.name}</h2>
                </div>
              </Polybot>
            )
          )}
        </GridPolybots>
      </FlexPolybots>
      <Footer />
    </>
  );
}
