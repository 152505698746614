import {
  ConnectButton,
  useActiveWallet,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { createAuth } from "thirdweb/auth";
import { createWallet, walletConnect } from "thirdweb/wallets";
import { client } from "../../context/client";
import { useEffect, useState } from "react";

import ConfettiComponent from "../confetti/Confetti";

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.trustwallet.app"),
  createWallet("io.zerion.wallet"),
  createWallet("me.rainbow"),
  createWallet("app.phantom"),
  walletConnect(),
];

export default function WalletConnect({ user }) {
  const wallet = useActiveWallet();
  const [address, setAddress] = useState(null);
  const jwt = localStorage.getItem("jwt");
  const [loggedIn, setLoggedIn] = useState(false);
  const [showMensage, setShowMensage] = useState(false);
  const [congratulations, setContratulations] = useState(false);

  const thirdwebAuth = createAuth({
    domain: "localhost:3000",
    client,
  });

  useEffect(() => {
    if (!user.wallet === "none") {
      setLoggedIn(true);
    }
  }, [user]);

  useEffect(() => {
    if (wallet) {
      setShowMensage(true);
    } else {
      setShowMensage(false);
    }
  }, [wallet]);

  useEffect(() => {
    async function fetchAddress() {
      if (wallet) {
        try {
          const address = wallet.getAccount();
          setAddress(address);

          if (address.address !== user.wallet.address) {
            const response = await fetch(
              "https://us-central1-polybots-d02df.cloudfunctions.net/linkWalletToUser",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  wallet: address,
                }),
              }
            );

            if (!response.ok) {
              throw new Error(`Erro na requisição: ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Resposta da API:", data);
          } else {
          }
        } catch (error) {
          console.error("Error in connect wallet or make the request:", error);
        }
        setContratulations(true);
      }
    }
    fetchAddress();
  }, [loggedIn]);

  return (
    <>
      {congratulations && <ConfettiComponent active={true} repeat={1} />}
      <ConnectButton
        client={client}
        wallets={wallets}
        theme={"light"}
        connectModal={{ size: "compact" }}
        connectButton={{ label: "Connect Wallet to Join LGF" }}
        auth={{
          getLoginPayload: async (params) => {
            // here you should call your backend, using generatePayload to return
            // a SIWE compliant login payload to the client
            return thirdwebAuth.generatePayload(params);
          },
          doLogin: async (params) => {
            // here you should call your backend to verify the signed payload passed in params
            // this will verify that the signature matches the intended wallet
            const verifiedPayload = await thirdwebAuth.verifyPayload(params);
            if (verifiedPayload.valid) {
              setLoggedIn(true);
              localStorage.setItem("auth", JSON.stringify(verifiedPayload));
            }
            // register user
          },
          isLoggedIn: async () => {
            // here you should ask you backend if the user is logged in
            // can use cookies, storage, or your method of choice
            const authData = localStorage.getItem("auth");
            return authData ? JSON.parse(authData).valid : false;
          },
          doLogout: async () => {
            // here you should call your backend to logout the user if needed
            // and delete any local auth tokens
            setContratulations(false);
            setLoggedIn(false);
          },
        }}
      />
      {showMensage && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "45%",
            backgroundColor: "#273444",
            marginTop: "20px",
            padding: "2px",
            borderRadius: "8px",
            display: "inline-block",
          }}
        >
          <h2
            style={{
              marginTop: "1rem",
              color: "#F0FFFF",
              fontSize: "2rem",
              // WebkitTextStroke: "1.5px #00bfff",
            }}
          >
            Entry the game and play the{" "}
            <span style={{ color: "#00bfff" }}>new mode</span> now!!!
          </h2>
        </div>
      )}
    </>
  );
}
