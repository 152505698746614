import React, { useEffect } from "react";

function GamesAreatwo() {
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .just-gamers-character {
        width: 85%;
      }

      @media (max-width: 1200px) {
        .just-gamers-character {
          width: 130%;
        }
      }
    `;
    document.head.append(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleButtonClick = () => {
    window.location.href = "/polybots";
  };

  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="section-title title-style-three white-title mb-70">
              <h2>
                FOR <span style={{ color: "#40a5f8" }}>FIGHTERS</span>
              </h2>
              <p>
                With customizable polybots, diverse game modes, and constant
                updates, Polybots Rumble offers a new universe to explore with
                adrenaline-filled battles.
              </p>
            </div>
            <div className="just-gamers-list">
              <ul>
                <li>
                  <div className="just-gamers-list-icon">
                    <img
                      style={{ width: "100%" }}
                      src="assets/img/medals/gold.png"
                      alt=""
                    />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Battlefield is your playground</h5>
                    <p>
                      Envision yourself at the helm of an unstoppable war
                      machine, fine-tuned by you in every aspect from powerful
                      weaponry to impenetrable defenses.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img
                      style={{ width: "100%" }}
                      src="assets/img/medals/silver.png"
                      alt=""
                    />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Adrenaline &amp; Strategy</h5>
                    <p>
                      Polybots Rumble is not for the faint of heart. Are you
                      ready to dominate the battlefield and make your mark in
                      the history of robotic warfare?
                    </p>
                  </div>
                </li>
                <li>
                  <div className="just-gamers-list-icon">
                    <img
                      style={{ width: "100%" }}
                      src="assets/img/medals/scoper.png"
                      alt=""
                    />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Show your Skill &amp; Intelligence</h5>
                    <p>
                      The action is relentless and immersive, with frequent
                      updates that keep the experience fresh and challenging.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 d-none d-lg-block">
            <div className="just-gamers-img">
              <img
                src="assets/img/images/just_gamers2_img.png"
                alt=""
                className="just-gamers-character"
              />
              <div
                className="just-gamers-circle-shape"
                style={{ marginLeft: "260px" }}
              >
                <img src="assets/img/images/gamers_circle_line.png" alt="" />
                <img
                  src="assets/img/images/gamers_circle_shape.png"
                  alt=""
                  className="rotateme"
                />
              </div>
              <img
                src="assets/img/images/just_gamers_chart.png"
                alt=""
                className="gamers-chart-shape"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="cta-btn"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "50px",
          marginTop: "30px",
        }}
      >
        <div
          className="btn btn-style-two"
          style={{ width: "250px", cursor: "pointer" }}
          onClick={handleButtonClick}
        >
          View Polybots
        </div>
      </div>
    </section>
  );
}

export default GamesAreatwo;
