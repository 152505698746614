import styled from "styled-components";

export const Popup = styled.div`
  width: 1190px;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: #1a1322;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  @media (max-width: 1050px) {
    width: 99%;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 1001;

  &:hover {
    color: #00bfff;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1200px) {
    top: 15px;
    right: 70px;
  }

  @media (max-width: 1050px) {
    top: 15px;
    right: 15px;
  }

  @media (max-width: 450px) {
    top: 5px;
    right: 10px;
  }
`;

export const PopupContent = styled.div`
  padding: 50px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 450px) {
    padding: 5px;
  }
`;

export const PopupHeader = styled.div`
  padding: 0 0 0 75px;
  display: grid;
  grid-template-columns: 3fr 5fr;
  height: 500px;
  margin-bottom: 6rem;

  img {
    width: 100%;
    height: inherit;
    margin-block: auto;
    margin-left: 20px;
    user-select: none;
  }

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: auto;
    padding: 0;
    margin-top: 40px;

    @media (max-width: 450px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: 330px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    img {
      margin-left: 0;
      height: auto;
      margin-top: 40px;
      margin-bottom: -50px;
      // @media (max-width: 450px) {
      //   margin-top: 0;
      // }
    }

    // > *:first-child {
    //   order: 2;
    // }

    // > img {
    //   order: 1;
    // }
  }
`;

export const PolybotRecord = styled.div`
  height: 100%;
  background: #201424;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid
    ${({ tier }) => {
      switch (tier) {
        case "C":
          return "rgba(176, 196, 222, 1)";
        case "B":
          return "rgba(0,255,127, 1)";
        case "A":
          return "rgba(52, 152, 219, 1)";
        case "S":
          return "rgba(138, 43, 226, 1)";
        case "S+":
          return "rgba(255, 69, 0, 1)";
        default:
          return "transparent";
      }
    }};

  box-shadow: 0 0 10px
    ${({ tier }) => {
      switch (tier) {
        case "C":
          return "rgba(176, 196, 222, 1)";
        case "B":
          return "rgba(0, 255, 127, 1)";
        case "A":
          return "rgba(52, 152, 219, 1)";
        case "S":
          return "rgba(138, 43, 226, 1)";
        case "S+":
          return "rgba(255, 69, 0, 1)";
        default:
          return "transparent";
      }
    }};

  h4 {
    margin: 5px 0px 5px 5px;
  }

  .blue {
    color: #00bfff;
  }
`;

export const RecordData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 25px;
  margin-block: 15px;

  h6 {
    color: #fff;
    margin-left: 10px;
  }
`;

export const ContainerStatusData = styled.div`
  margin-top: 1rem;
  width: 90%;
  margin-inline: auto;
  padding: 10px 10px 0 20px;
  border-radius: 10px;
  background: #273444;
  color: #fff;
`;

export const StatusData = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-block: 5px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
`;

export const Robot = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .robot {
    height: 750px;
    width: auto;
    user-select: none;
  }

  img {
    width: 120px;
    user-select: none;
  }

  .chest {
    height: 50px;
    position: absolute;
    top: 160px;
    left: 140px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  .leftArm {
    height: 50px;
    position: absolute;
    top: 385px;
    left: 75px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  .legs {
    height: 50px;
    position: absolute;
    bottom: 115px;
    left: 75px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  .head {
    height: 50px;
    position: absolute;
    top: 180px;
    right: 230px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  .rightArm {
    height: 50px;
    position: absolute;
    top: 395px;
    right: 115px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  @media (max-width: 1050px) {
    display: none;
  }
`;

export const RobotChip = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;

  h2 {
    grid-column: 1;
    margin-top: 50px;

    @media (max-width: 1000px) {
      margin-bottom: -30px;
    }
  }

  div {
    height: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  img {
    height: 75%;
    width: auto;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.03);
    }

    @media (max-width: 1000px) {
      margin-bottom: -120px;
    }
  }

  h6 {
    margin: 0 auto 1rem;
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
    width: 100%;

    @media (max-width: 1050px) {
      text-align: center;
      width: 70%;
    }

    @media (max-width: 450px) {
      font-size: 0.9rem;
    }
  }

  span {
    color: #3498db;
    margin-right: 4px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: stretch;
    text-align: left;

    h2 {
      grid-column: span 2;
    }

    h6 {
      margin-right: auto;
      margin-bottom: 1.5rem;
    }
  }
`;

export const GearStats = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  img {
    height: auto;
    width: 150px;
    user-select: none;
  }

  .gear-status-container {
    padding: 10px;
    margin-block: 10px;
    background-color: #273444;
    border-radius: 10px;
    height: 350px;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    @media (max-width: 750px) {
      flex-direction: column;
      height: auto;
      width: 300px;
      padding: 5px;
      margin-block: 2px;
      margin-top: 25px;
    }

    @media (max-width: 450px) {
      width: 300px;
      margin: 0 auto;
    }

    @media (max-width: 350px) {
      width: 270px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  .gear-status-container-img-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    @media (max-width: 750px) {
      margin-left: 0px;
    }
  }

  .border-status {
    border: 2px solid #00bfff;
    margin-top: 0px;

    @media (max-width: 750px) {
      margin-top: -30px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const RobotSkills = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  grid-template-rows: auto-rows;

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }

  h6 {
    margin-right: auto;
    margin-bottom: 1.5rem;
  }

  span {
    color: #3498db;
    margin-right: 4px;
  }

  .icon {
    grid-column: span 2;
    grid-row: span 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .chest {
    grid-row: span 2;
  }

  img {
    height: auto;
    width: 200px;
    user-select: none;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    div {
      margin-bottom: 2rem;
      align-items: center;
      text-align: center;
    }

    h6 {
      margin-right: 0;
      text-align: center;

      @media (max-width: 1050px) {
        width: 50%;
      }

      @media (max-width: 500px) {
        width: 60%;
      }
    }

    .icon {
      grid-column: span 1;
      grid-row: span 1;
    }

    .chest {
      grid-row: span 1;
    }

    img {
      width: 100%;
      max-width: 200px;
    }
  }
`;

export const RobotBoxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    margin-top: 10px;
    &:hover {
      transition: 0.5s;
      transform: scale(1.1);
    }
  }

  h6 {
    margin-left: 75px;
  }

  @media (max-width: 1000px) {
    .no-margin {
      margin-bottom: -120px;
    }

    h6 {
      margin-left: 40px;
    }
  }
`;

export const StarDiv = styled.div`
  margin-left: 3px;

  @media (max-width: 1050px) {
    margin-left: -7px;
  }

  @media (max-width: 550px) {
    margin-left: -5px;
  }

  @media (max-width: 450px) {
    margin-left: -7px;
  }

  @media (max-width: 400px) {
    margin-left: -7px;
  }

  @media (max-width: 360px) {
    margin-left: -7px;
  }
`;

export const StarrrDiv = styled.div`
  margin-right: 15px;

  @media (max-width: 1050px) {
    margin-left: -22px;
  }

  @media (max-width: 400px) {
    margin-right: -10px;
  }
`;
