import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Footer from "../../../components/footer/Footer";
import { Fade } from "react-reveal";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";

import { auth } from "../../../context/firebase";
import styled from "styled-components";

Modal.setAppElement("#root");

function LoginPage() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const referParam = queryParams.get("refer");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      if (idToken) {
        localStorage.setItem("jwt", idToken);
        setLoading(false);
        window.location.href = "/profile";
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAppleLogin = async () => {
    setLoading(true);
    const provider = new OAuthProvider("apple.com");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();
      if (idToken) {
        setSuccess(true);
        localStorage.setItem("jwt", idToken);
        window.location.href = "/profile";
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.dataLayer = window.dataLayer || [];

    setEmailError(!email);
    setPasswordError(!password);

    setLoading(true);

    try {
      setLoading(true);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      if (idToken) {
        setSuccess(true);
        localStorage.setItem("jwt", idToken);
        window.location.href = "/profile";
      } else {
        setModalMessage("Failed to log user.");
        setIsModalOpen(true);
      }
    } catch (error) {
      const errorCode = error.code;

      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/invalid-credential"
      ) {
        setModalMessage(
          "Invalid credentials. Please check your email and password."
        );
      } else if (errorCode === "auth/wrong-password") {
        setModalMessage("Password Incorrect.");
      } else {
        setModalMessage("An unexpected error occurred.");
      }
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (success) {
      history.push("/");
    }
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div className="container">
            <div
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1c1424",
                  borderRadius: "10px",
                  minHeight: "650px",
                  minWidth: "320px",
                  height: "80%",
                  margin: "50px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                  }}
                >
                  <h1
                    style={{
                      marginTop: "50px",
                      fontSize: "50px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Login to open{" "}
                    <span style={{ color: "#40a5f8" }}>Your Garage</span>
                  </h1>
                  <p style={{ width: "100%", marginBottom: "30px" }}>
                    Login with the same email that you have your polybots
                    account
                  </p>
                  <div className="newsletter-form">
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "30px",
                      }}
                      onSubmit={handleSubmit}
                    >
                      <div className="newsletter-form-grp">
                        <i className="fa fa-envelope" />
                        <input
                          type="email"
                          placeholder="Enter your email..."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {emailError && (
                          <span style={{ color: "red" }}>Put your Email</span>
                        )}
                      </div>
                      <div className="newsletter-form-grp">
                        <i className="fa fa-lock" />
                        <input
                          type="password"
                          placeholder="Enter your password..."
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {nameError && (
                          <span style={{ color: "red" }}>Put your name</span>
                        )}
                      </div>
                      <Fade up delay={0.8}>
                        <button
                          id="submitalpha"
                          type="submit"
                          className="btn-style-two"
                          style={{
                            width: "200px",
                            zIndex: "100",
                          }}
                          // disabled={loading}
                        >
                          Login
                        </button>
                      </Fade>
                      {!isSmallScreen && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5%",
                            marginTop: "15px",
                          }}
                        >
                          <StyledImage
                            src="assets/img/buttons/google-button-login.png"
                            alt="Apple Store Button"
                            onClick={handleGoogleLogin}
                          />

                          <StyledImage
                            src="assets/img/buttons/apple-button-login.png"
                            alt="Apple Store Button"
                            onClick={handleAppleLogin}
                          />
                        </div>
                      )}

                      {isSmallScreen && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "60px",
                            marginBottom: "30px",
                            marginTop: "20px",
                          }}
                        >
                          <StyledImage
                            src="assets/img/buttons/google-button-login-mobile.png"
                            alt="Apple Store Button"
                            onClick={handleGoogleLogin}
                            style={{ width: "50px" }}
                          />

                          <StyledImage
                            src="assets/img/buttons/apple-button-login-mobile.png"
                            alt="Apple Store Button"
                            onClick={handleAppleLogin}
                            style={{ width: "50px" }}
                          />
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {
        // If is ModalOpen the Wrapper and the PopUp appears
        isModalOpen && (
          // Wrapper
          <div
            style={{
              display: "block flex",
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              zIndex: "1000",
            }}
          >
            {/* PopUp */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "350px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#1c1424",
                border: "2px solid #666",
                borderRadius: "10px",
                padding: "40px",
              }}
            >
              <button
                onClick={closeModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  color: "#fff",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              <h4>{modalMessage}</h4>
              <button
                onClick={closeModal}
                className="btn btn-style-two"
                style={{ marginTop: "20px" }}
              >
                OK
              </button>
            </div>
          </div>
        )
      }
    </>
  );
}

const StyledImage = styled.img`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.95;
  width: 200px;
  height: 100px
  cursor: pointer;
  margin-top: -10px;

  @media (max-width: 765px) {
    margin-right: 0px;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

export default LoginPage;
