import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components';

function GamesArea() {

  const StyledImage = styled.img`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.95;
  &:hover {
    opacity: 1;
    transform: scale(1.1); 
  }
`;

  return (
	 <section className="featured-game-area position-relative pt-115 pb-90">
            <div className="featured-game-bg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>FEATURED <span style={{color:"#40a5f8"}}>POLYBOTS</span></h2>
                    {/* <p>Compete with 100 players on a remote island for winner takes showdown
                      known issue where certain skin strategic</p> */}
                  </div>
                </div>
              </div>
              <div className="row featured-active">
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                  {/* featured-game-item */}
                    <div className="featured-game-thumb">
                      <StyledImage src="assets/img/cards/cards-01.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                    <div className="featured-game-thumb">
                      <StyledImage src="assets/img/cards/cards-03.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                    <div className="featured-game-thumb">
                      <StyledImage src="assets/img/cards/cards-02.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                    <div className="featured-game-thumb">
                      <StyledImage src="assets/img/cards/cards-05.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                    <div className="featured-game-thumb">
                     <StyledImage src="assets/img/cards/cards-04.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="mb-30">
                    <div className="featured-game-thumb">
                      <StyledImage src="assets/img/cards/cards-06.png" alt="" />
                    </div>
                    {/* <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span style={{color:"#40a5f8"}}>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default GamesArea