import React from "react";
import styled from "styled-components";

const Titles = ({ selectedTitle = null }) => {
  const titles = [
    {
      title: "Bronze",
      tiers: [
        { tier: "I", level: 1, points: "1", image: "/assets/img/wiki/tiers-rank/bronze1.png" },
        { tier: "II", level: 2, points: "100", image: "/assets/img/wiki/tiers-rank/bronze2.png" },
        { tier: "III", level: 3, points: "120", image: "/assets/img/wiki/tiers-rank/bronze3.png"},
        { tier: "IV", level: 4, points: "144", image: "/assets/img/wiki/tiers-rank/bronze4.png" },
        { tier: "V", level: 5, points: "173", image: "/assets/img/wiki/tiers-rank/bronze5.png" },
      ],
    },
    {
      title: "Silver",
      tiers: [
        { tier: "I", level: 6, points: "207", image: "/assets/img/wiki/tiers-rank/silver1.png" },
        { tier: "II", level: 7, points: "249", image: "/assets/img/wiki/tiers-rank/silver2.png" },
        { tier: "III", level: 8, points: "299", image: "/assets/img/wiki/tiers-rank/silver3.png" },
        { tier: "IV", level: 9, points: "358", image: "/assets/img/wiki/tiers-rank/silver4.png" },
        { tier: "V", level: 10, points: "430", image: "/assets/img/wiki/tiers-rank/silver5.png" },
      ],
    },
    {
      title: "Gold",
      tiers: [
        { tier: "I", level: 11, points: "516", image: "/assets/img/wiki/tiers-rank/gold1.png"},
        { tier: "II", level: 12, points: "619", image: "/assets/img/wiki/tiers-rank/gold2.png"},
        { tier: "III", level: 13, points: "743", image: "/assets/img/wiki/tiers-rank/gold3.png" },
        { tier: "IV", level: 14, points: "892", image: "/assets/img/wiki/tiers-rank/gold4.png"},
        { tier: "V", level: 15, points: "1070", image: "/assets/img/wiki/tiers-rank/gold5.png" },
      ],
    },
    {
      title: "Diamond",
      tiers: [
        { tier: "I", level: 16, points: "1284", image: "/assets/img/wiki/tiers-rank/diamond1.png" },
        { tier: "II", level: 17, points: "1541", image: "/assets/img/wiki/tiers-rank/diamond2.png" },
        { tier: "III", level: 18, points: "1849", image: "/assets/img/wiki/tiers-rank/diamond3.png" },
        { tier: "IV", level: 19, points: "2219", image: "/assets/img/wiki/tiers-rank/diamond4.png" },
        { tier: "V", level: 20, points: "2662", image: "/assets/img/wiki/tiers-rank/diamond5.png"},
      ],
    },
    {
      title: "Platinum",
      tiers: [
        { tier: "I", level: 21, points: "3195", image: "/assets/img/wiki/tiers-rank/diamond1.png"},
        { tier: "II", level: 22, points: "3834", image: "/assets/img/wiki/tiers-rank/diamond2.png" },
        { tier: "III", level: 23, points: "4601", image: "/assets/img/wiki/tiers-rank/diamond3.png" },
        { tier: "IV", level: 24, points: "5521", image: "/assets/img/wiki/tiers-rank/diamond4.png" },
        { tier: "V", level: 25, points: "6625", image: "/assets/img/wiki/tiers-rank/diamond5.png" },
      ],
    },
    {
      title: "Pro",
      tiers: [
        { tier: "I", level: 26, points: "Regional ", image: "/assets/img/wiki/tiers-rank/diamond1.png" },
        { tier: "II", level: 27, points: "Mundial", image: "/assets/img/wiki/tiers-rank/diamond2.png" },
        { tier: "III", level: 28, points: "Elite", image: "/assets/img/wiki/tiers-rank/diamond3.png" },
      ],
    },
  ];

  const displayedTitles =
    selectedTitle !== null &&
    selectedTitle >= 0 &&
    selectedTitle < titles.length
      ? [titles[selectedTitle]]
      : [];

  return (
    <Container>
      {displayedTitles.length > 0 ? (
        displayedTitles.map((titleData, index) => (
          <TitleContainer key={index}>
            <TitleName style={{ marginBottom: "60px" }}>
              {titleData.title}
            </TitleName>
            {titleData.tiers.map((tierData, tierIndex) => (
              <TierContainer key={tierIndex}>
                <LeftSide>
                  <TierName>Tier: {tierData.tier}</TierName>
                  <Medal
                    src={tierData.image}
                    alt={`${titleData.title} Medal Tier ${tierData.tier}`}
                  />
                </LeftSide>
                <RightSide>
                  <TitleInfo>
                    <HighlightedText>Rank: </HighlightedText> {tierData.level}
                  </TitleInfo>
                  <TitleInfo>
                    <HighlightedText>Points: </HighlightedText>{" "}
                    {tierData.points}
                  </TitleInfo>
                </RightSide>
              </TierContainer>
            ))}
          </TitleContainer>
        ))
      ) : (
        <NoTitleContainer>
          <TitleName>Ranks and Tiers</TitleName>
          {/* <PlaceHolder /> */}
          <Description>
            Ranks represent your progress and achievements in the game. As you
            play more games and earn points, you will rise through different
            ranks. Each rank has multiple tiers that reflect your overall
            performance and dedication. Continue playing and achieving higher
            ranks to showcase your skills and accomplishments!
            <br></br>
            <br></br>
            Your XP and RP contribute to your rank, which ranges from Bronze to
            the prestigious Pro Player tiers. Each rank encompasses multiple
            levels, reflecting your dedication and skill.
          </Description>
        </NoTitleContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;

  @media (max-width: 766px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
`;

const TierContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 30px;

  @media (max-width: 766px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightSide = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

const TierName = styled.h4`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const Medal = styled.img`
  width: 150px;
  height: 150px;
`;

const TitleInfo = styled.p`
  font-size: 1em;
  margin: 3px 0;
`;

const NoTitleContainer = styled.div`
  text-align: center;
`;

const TitleName = styled.h2`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 766px) {
    font-size: 2.5em;
  }
`;

const Description = styled.p`
  font-size: 1em;
  margin-top: 20px;
  line-height: 1.5;
  text-align: left;

  @media (max-width: 766px) {
    text-align: justify;
  }
`;

const PlaceHolder = styled.div`
  width: 100%;
  height: 350px;
  background-color: #f5f5f5;
`;

export default Titles;
