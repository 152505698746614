import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Fade } from "react-reveal";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";

Modal.setAppElement("#root");

function Refers() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [communityName, setCommunityName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [communityNameError, setCommunityNameError] = useState(false);
  const [mensage, setMensage] = useState("Link to your community:");

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError(!email);
    setNameError(!name);
    setCommunityNameError(!communityName);

    if (!email || !name || !communityName) {
      return;
    }

    const generatedLink = `https://polybotsgame.io/alpha?refer=${encodeURIComponent(
      communityName
    )}`;
    setLink(generatedLink);

    setLoading(true);
    try {
      const requestBody = {
        email: email,
        name: name,
        communityName: communityName,
      };

      const response = await fetch(
        "https://us-central1-polybots-d02df.cloudfunctions.net/createCommunity",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 400) {
        setMensage("Community already exists:");
        setSuccess(false);
        setIsModalOpen(true);
      } else if (!response.ok) {
        throw new Error("Failed to register Community.");
      } else {
        setSuccess(true);
        setIsModalOpen(true);
        setMensage("Link to your community:");
        console.log("Community registered successfully!");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div className="container">
            <div
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1c1424",
                  borderRadius: "10px",
                  minHeight: "600px",
                  minWidth: "320px",
                  height: "60%",
                  margin: "50px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <h1
                    style={{
                      marginTop: "50px",
                      fontSize: "50px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <span style={{ color: "#40a5f8" }}>Community</span> Link
                  </h1>
                  <p style={{ width: "68%", marginBottom: "30px" }}>
                    Join the action and create your unique referral link to
                    invite people to our epic robot battles!
                  </p>
                  <div className="newsletter-form">
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "30px",
                      }}
                      onSubmit={handleSubmit}
                    >
                      <div className="newsletter-form-grp">
                        <i className="fa fa-envelope" />
                        <input
                          type="email"
                          placeholder="Enter your email..."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {emailError && (
                          <span style={{ color: "red" }}>Put your Email</span>
                        )}
                      </div>
                      <div className="newsletter-form-grp">
                        <i className="fa fa-user" />
                        <input
                          type="text"
                          placeholder="Enter your name..."
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {nameError && (
                          <span style={{ color: "red" }}>Put your name</span>
                        )}
                      </div>

                      <div className="newsletter-form-grp">
                        <i className="fa fa-users" />
                        <input
                          type="text"
                          placeholder="Enter your community name..."
                          value={communityName}
                          onChange={(e) => setCommunityName(e.target.value)}
                          required
                        />
                        {communityNameError && (
                          <span style={{ color: "red" }}>
                            Put your Community Name
                          </span>
                        )}
                      </div>
                      <Fade up delay={0.8}>
                        <button
                          type="submit"
                          className="btn-style-two"
                          style={{
                            width: "200px",
                            zIndex: "100",
                            marginBottom: "50px",
                            cursor: isModalOpen ? "not-allowed" : "pointer",
                            pointerEvents: isModalOpen ? "none" : "auto",
                          }}
                          disabled={loading || isModalOpen}
                        >
                          {loading ? "Submitting..." : "SUBMIT"}
                        </button>
                      </Fade>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {isModalOpen && (
        <div
          style={{
            display: "block flex",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              backgroundColor: "#1c1424",
              border: "2px solid #666",
              borderRadius: "10px",
              padding: "40px",
            }}
          >
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            <h2>{mensage}</h2>
            <h6>{link}</h6>
            <button
              onClick={copyToClipboard}
              className="btn btn-style-two"
              style={{ marginTop: "20px" }}
            >
              COPY
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Refers;
